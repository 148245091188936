import * as React from 'react';
import { graphql } from 'gatsby';
import ContextConsumer from '../../helpers/context';

import Template from '../../templates/news';

const NewsPage = ({ data }) => {
  const news = data.med.pageNews;

  return (
    <ContextConsumer>
      {({ data, set }) => (
        <Template
          contextData={data}
          contextSet={set}
          langTo={'/updates'}
          data={news}
        />
      )}
    </ContextConsumer>
  );
};

export const pageNewsEnQuery = graphql`
  {
    med {
      pageNews(where: { id: "ckjy4jq2g6ads0a53o6w8jkpj" }, locales: en) {
        items {
          ... on MED_Article {
            slug
            title
            intro
          }
          ... on MED_ExternalLink {
            heading
            text
            link
          }
        }
        localizations(includeCurrent: true, locales: pl_PL) {
          items {
            ... on MED_Article {
              id
              thumbnailImage {
                url
                height
                handle
                width
              }
            }
            ... on MED_ExternalLink {
              id
              image {
                handle
                height
                url
                width
              }
            }
          }
        }
        seoDescription
        seoImage {
          url
        }
        seoTitle
        title
        intro
      }
    }
  }
`;

export default NewsPage;
